@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer base {
	@font-face {
		font-family: 'JLREmericWeb-ExtraLight';
		src: local('jlr'), url('../public/fonts/JLREmericWeb-ExtraLight.woff') format('woff');
	}

	@font-face {
		font-family: 'JLREmericWeb-Regular';
		src: url('../public/fonts/JLREmericWeb-Regular.woff') format('woff');
	}

	@font-face {
		font-family: 'JLREmericWeb-SemiBold';
		src: url('../public/fonts/JLREmericWeb-SemiBold.woff') format('woff');
	}

	/* body {
    font-family: 'JLREmericWeb-ExtraLight'
  } */
}

/* Corporate Identity colours 
  --color-grey-black: #1E1E1E;
  --color-white: #FFFFFF;
  --color-jaguar-red: #9E1B32;
  --color-lr-green: #005A2B;
  --color-grey-1: #525252
  --color-grey-1-5: #6F6F6F
  --color-grey-2: #8C8C8C
  --color-grey-2-5: #A8A8A8
  --color-grey-3: #C4C4C4
  --color-grey-3-5: #D6D6D6
  --color-grey-4: #E8E8E8
  --color-warm-1: #6C4646
  --color-warm-1-5: #956551
  --color-warm-2: #BD845B
  --color-warm-2-5: #C1996D
  --color-warm-3: #C5AD7E
  --color-warm-3-5: #D4C19E
  --color-warm-4: #E2D5BE
  --color-cool-1: #40465C
  --color-cool-1-5: #4E5F76
  --color-cool-2: #5B788F
  --color-cool-2-5: #78939E
  --color-cool-3: #95ADAC
  --color-cool-3-5: #B1C2C3
  --color-cool-4: #CDD7D9
*/

/* custom / light */
:root {
	--color-primary-1: 30 30 30;
	--color-primary-2: 255 255 255;
	--color-brand-1: 158 27 50;
	--color-brand-2: 0 90 43;
	--color-secondary-1: 82 82 82;
	--color-secondary-1-5: 111 111 111;
	--color-secondary-2: 140 140 140;
	--color-secondary-2-5: 168 168 168;
	--color-secondary-3: 196 196 196;
	--color-secondary-3-5: 214 214 214;
	--color-secondary-4: 232 232 232;
	--color-tertiary-warm-1: 108 70 70;
	--color-tertiary-warm-1-5: 149 101 81;
	--color-tertiary-warm-2: 189 132 91;
	--color-tertiary-warm-2-5: 193 153 109;
	--color-tertiary-warm-3: 197 173 126;
	--color-tertiary-warm-3-5: 212 193 158;
	--color-tertiary-warm-4: 226 213 190;
	--color-tertiary-cool-1: 64 70 92;
	--color-tertiary-cool-1-5: 78 95 118;
	--color-tertiary-cool-2: 91 120 143;
	--color-tertiary-cool-2-5: 120 147 158;
	--color-tertiary-cool-3: 149 173 172;
	--color-tertiary-cool-3-5: 177 194 195;
	--color-tertiary-cool-4: 205 215 217;
}

/* dark */
.dark {
	--color-primary-1: 255 255 255;
	--color-primary-2: 30 30 30;
	--color-brand-1: 158 27 50;
	--color-brand-2: 0 90 43;
	--color-secondary-4: 82 82 82;
	--color-secondary-3-5: 111 111 111;
	--color-secondary-3: 140 140 140;
	--color-secondary-2-5: 168 168 168;
	--color-secondary-2: 196 196 196;
	--color-secondary-1-5: 214 214 214;
	--color-secondary-1: 232 232 232;
	--color-tertiary-warm-4: 108 70 70;
	--color-tertiary-warm-3-5: 149 101 81;
	--color-tertiary-warm-3: 189 132 91;
	--color-tertiary-warm-2-5: 193 153 109;
	--color-tertiary-warm-2: 197 173 126;
	--color-tertiary-warm-1-5: 212 193 158;
	--color-tertiary-warm-1: 226 213 190;
	--color-tertiary-cool-4: 64 70 92;
	--color-tertiary-cool-3-5: 78 95 118;
	--color-tertiary-cool-3: 91 120 143;
	--color-tertiary-cool-2-5: 120 147 158;
	--color-tertiary-cool-2: 149 173 172;
	--color-tertiary-cool-1-5: 177 194 195;
	--color-tertiary-cool-1: 205 215 217;
}

/* adding this here until I work out how to style the focussed on the @mention text area.*/
textarea:focus {
	outline: 1px solid #8c8c8c;
	outline-offset: -1px;
}
