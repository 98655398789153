#container {
	top: 50%;
	left: 50%;
	position: absolute;
	display: block;
	transform: translate(-50%, -50%);
	z-index: 1;
	/* width: 100% */
}

#logoWrapper {
	/* background: radial-gradient(ellipse, white 50%, transparent 70%); */
	border-radius: 20px;
	/* border: 2px solid #40465C; */
	background: white;
	position: relative;
	height: 180px;
	width: 420px;
	margin: -20px 0 auto;
	background-color: rgb(255, 255, 255, 0.8);
	opacity: 0;
	transition: opacity 2s;
}

#logoWrapper.run-animation {
	animation: logo-wrapper 2s ease forwards 0s;
}

/* Here */
#appHeadline {
	position: absolute;
	width: 357px;
	top: calc(50% - 20px);
	left: calc(50% - 1px);
	transform: translate(-50%, -50%);
	min-width: 362px;
	min-height: 118px;
	fill: rgb(158, 27, 50);
}
#appHeadline path {
	stroke: rgb(158, 27, 50);
	stroke-width: 3;
}

#appStrapline {
	position: absolute;
	width: 392px;
	bottom: 0px;
	left: 50%;
	transform: translate(-50%, -50%);
	min-width: 397px;
	min-height: 27px;
	fill: #40465c;
}

#appStrapline path {
	stroke: #40465c;
	stroke-width: 1;
}

@keyframes logo-wrapper {
	from {
		opacity: 0;
		margin-top: -80px;
	}

	to {
		opacity: 1;
		margin-top: -20px;
	}
}
